const missing = (target) =>
  ["", null, undefined].includes(target) || (Array.isArray(target) && target.length === 0);

function commonCompare(alpha, bravo) {
  const pickNum = (str) => {
    const num = str.match(/^[0-9]+/);
    if (num === null) return [null, str];
    return [parseInt(num[0]), str.slice(num[0].length)];
  };
  const pickNotNum = (str) => {
    const notNum = str.match(/^[^0-9]+/);
    if (notNum === null) return [null, str];
    return [notNum[0], str.slice(notNum[0].length)];
  };

  if (missing(alpha) || missing(bravo)) {
    throw new Error("Cannot compare");
  }

  do {
    let numA, numB, notNumA, notNumB;
    if (alpha === bravo) return 0;
    [numA, alpha] = pickNum(alpha);
    [numB, bravo] = pickNum(bravo);
    if (numA !== numB) return numA > numB ? 1 : -1;
    [notNumA, alpha] = pickNotNum(alpha);
    [notNumB, bravo] = pickNotNum(bravo);
    if (notNumA !== notNumB) {
      // delimiter mismatch or end of string
      if (notNumA === null) {
        alpha = "0";
        continue;
      }
      if (notNumB === null) {
        bravo = "0";
        continue;
      }
      throw new Error("Cannot compare"); /* delimiter mismatch */
    }
  } while (alpha.length > 0);
  return 0;
}

export function parseVulnerableVersions(vArray) {
  // vArray shuld be VulnerableVersions generated by trivy.
  if (missing(vArray)) {
    return [];
  }
  return vArray.reduce(
    (ret, val) =>
      val.split("||").reduce(
        (ret2, val2) => [
          ...ret2,
          {
            ge: val2.split(/>= *([^<>= ,]+)/)[1],
            gt: val2.split(/> *([^<>= ,]+)/)[1],
            le: val2.split(/<= *([^<>= ,]+)/)[1],
            lt: val2.split(/< *([^<>= ,]+)/)[1],
            eq: val2.split(/(?<![<>])= *([^<>= ,]+)/)[1],
          },
        ],
        ret,
      ),
    [],
  );
}

export function versionMatch(target, ge, gt, le, lt, eq, onError) {
  if (missing(target)) {
    return onError;
  }
  if ([ge, gt, le, lt, eq].every((item) => missing(item))) {
    return onError; /* no rule to compare */
  }
  if (
    (!missing(eq) && [ge, gt, le, lt].some((item) => !missing(item))) ||
    (!missing(gt) && !missing(ge)) ||
    (!missing(lt) && !missing(le))
  ) {
    return onError; /* ambiguous */
  }
  const compare = commonCompare;
  try {
    if (!missing(eq)) {
      return compare(target, eq) === 0;
    }

    if (!missing(lt)) {
      if (compare(target, lt) >= 0) {
        return false;
      }
    } else if (!missing(le)) {
      if (compare(target, le) > 0) {
        return false;
      }
    }

    if (!missing(gt)) {
      if (compare(target, gt) <= 0) {
        return false;
      }
    } else if (!missing(ge)) {
      if (compare(target, ge) < 0) {
        return false;
      }
    }
    return true;
  } catch (error) {
    return onError;
  }
}
